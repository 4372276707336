import { ApiService } from './apiService/apiService'
import { REACT_TOKEN_AUTH_KEY } from './constants'
import { getBackendUrl } from './utils'

const API_URL = getBackendUrl()

export const apiClient = new ApiService({
  baseUrl: API_URL,
  validation: true,
  refreshTokenFunc: async () => {
    const token = await JSON.parse(
      sessionStorage.getItem(REACT_TOKEN_AUTH_KEY) as string,
    )

    if (!token?.access) {
      return ''
    }

    return token.access
  },
})
