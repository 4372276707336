type NavModules = {
  id: number
  name: string
  url: string
}[][]

type Links = {
  gs?: string
  sm?: string
  em?: string
  fm?: string
  cm?: string
  t?: string
  tm?: string
  gt?: string
  lk?: string
  chm?: string
}

export const getCurrentEnvModulesData = (envLinks: string): NavModules => {
  const links: Links = JSON.parse(envLinks)
  const gStationModules = [
    { id: 1, name: 'G-Station', url: links.gs ? `https://${links.gs}` : '' },
  ].filter(module => module.url)
  const otherModules = [
    {
      id: 1,
      name: 'Set-management',
      url: links.sm ? `https://${links.sm}` : '',
    },
    { id: 2, name: 'Объёмы', url: links.em ? `https://${links.em}` : '' },
    {
      id: 3,
      name: 'Проверки',
      url: links.cm ? `https://${links.cm}` : '',
    },
    {
      id: 4,
      name: 'Стройконтроль',
      url: links.fm ? `https://${links.fm}` : '',
    },
    {
      id: 4,
      name: 'Чек-листы / Замечания',
      url: links.chm ? `https://${links.chm}` : '',
    },
  ].filter(module => module.url)

  const ourModules = [
    {
      id: 1,
      name: 'Тендеры',
      url: links.t ? `https://${links.t}` : '',
    },
    {
      id: 2,
      name: 'Гранд-тендеры',
      url: links.gt ? `https://${links.gt}` : '',
    },
    {
      id: 3,
      name: 'Личный кабинет',
      url: links.lk ? `https://${links.lk}` : '',
    },
    {
      id: 4,
      name: 'Рассылка',
      url: links.tm ? `https://${links.tm}` : '',
    },
  ].filter(module => module.url)

  return [ourModules, otherModules, gStationModules]
}
