import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProviderDetail, ProviderInfo, TRow, WithData } from 'src/@types/api'
import { apiClient } from 'src/services/apiClient'

export const getProviderDetailAction = createAsyncThunk<
  WithData<ProviderDetail>,
  void
>('provider/getProviders', async (_, { rejectWithValue }) => {
  const [err, res] = await apiClient.get<WithData<ProviderDetail>>({
    path: '/provider',
  })

  if (err) {
    throw rejectWithValue(err)
  }

  return res.data
})

export const updateProviderDetail = createAsyncThunk<unknown, TRow>(
  'provider/updateProviderDetail',
  async (values, { rejectWithValue }) => {
    const [err, res] = await apiClient.put({ path: '/provider', body: values })

    if (err) {
      throw rejectWithValue(err)
    }

    return res.data
  },
)

export const getProviderInfo = createAsyncThunk<WithData<ProviderInfo>, void>(
  'provider/getProviderDetailUpdate',
  async (_, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<WithData<ProviderInfo>>({
      path: '/provider/info',
    })

    if (err) {
      throw rejectWithValue(err)
    }

    return res.data
  },
)
