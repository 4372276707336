import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import {
  APP_PAGES,
  TENDER_TASK_START_PAGE,
  AGREEMENT_START_PATH,
} from 'src/constants/routes'
import { AppHeader } from 'src/components/app-header'

import Auth from 'src/pages/auth'
import TenderTasks from 'src/pages/tender-tasks'

import { Maintenance } from '@gmini/ui-kit'

import { getMaintenance } from 'src/store/feature/maintenance/maintenance-selectors'

import { useAppDispatch } from '../store'
import { getIsAuthSelector, getUserDetail } from '../store/feature/user'

const CompanyProfile = React.lazy(() => import('src/pages/company-profile'))
const NotFound = React.lazy(() => import('src/pages/not-found'))
const TenderTaskDetail = React.lazy(
  () => import('src/pages/tender-task-detail'),
)
const ChangePassword = React.lazy(() => import('src/pages/change-password'))
const Agreements = React.lazy(() => import('src/pages/agreements'))
const AgreementDetail = React.lazy(() => import('src/pages/agreement-detail'))

export const App: React.FC = () => {
  const dispatch = useAppDispatch()
  const isAuth = useSelector(getIsAuthSelector)

  useEffect(() => {
    dispatch(getUserDetail())
  }, [dispatch, isAuth])

  const maintenanceEnabled = useSelector(getMaintenance)

  if (maintenanceEnabled) {
    return <Maintenance />
  }

  if (!isAuth) {
    return <Auth />
  }

  return (
    <>
      <AppHeader />
      <main>
        <Switch>
          <Route path={APP_PAGES.COMPANY_PROFILE} component={CompanyProfile} />
          <Route exact={true} path={[APP_PAGES.ROOT, APP_PAGES.TENDER_TASKS]}>
            <Redirect to={TENDER_TASK_START_PAGE} />
          </Route>
          <Route exact={true} path={APP_PAGES.AGREEMENTS}>
            <Redirect to={AGREEMENT_START_PATH} />
          </Route>
          <Route path={APP_PAGES.AGREEMENT_TAB} component={Agreements} />
          <Route
            path={APP_PAGES.AGREEMENT_DETAIL}
            component={AgreementDetail}
          />
          <Route
            path={APP_PAGES.TENDER_TASK_DETAIL}
            component={TenderTaskDetail}
          />
          <Route path={APP_PAGES.TENDER_TASK_TAB} component={TenderTasks} />
          <Route path={APP_PAGES.CHANGE_PASSWORD} component={ChangePassword} />
          <Route path={APP_PAGES.NOT_FOUND} component={NotFound} />
          <Redirect to={APP_PAGES.NOT_FOUND} />
        </Switch>
      </main>
    </>
  )
}
