import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Grid,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

export type AlertProps = {
  open: boolean
  title: string
  contentText: string
  handleClose: () => void
  handleConfirm: () => void
}

export const Alert: React.FC<AlertProps> = ({
  open,
  title,
  contentText,
  handleClose,
  handleConfirm,
}) => (
  <Dialog onClose={handleClose} open={open} fullWidth={true}>
    <DialogTitle disableTypography={true}>
      <Grid container={true} justify='space-between'>
        <Typography variant='h6'>{title}</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>{contentText}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} variant='contained'>
        Отмена
      </Button>
      <Button onClick={handleConfirm} variant='contained' color='secondary'>
        Выйти
      </Button>
    </DialogActions>
  </Dialog>
)
