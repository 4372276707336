import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Button, TextField } from '@material-ui/core'
import { ValidationHelper } from 'src/utils/validation/validation'
import { LinkComponent } from 'src/components/link'

import '../../auth.scss'
import { useAppDispatch } from 'src/store'
import { restorePassword } from 'src/store/feature/user/actions'

const RestoreSchema = yup.object().shape({
  email: yup.string().trim().required('Обязательное поле').test({
    name: 'email',
    message: 'Логин должен быть корректным email адресом',
    test: ValidationHelper.testEmail,
  }),
})

export const Restore: React.FC = () => {
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: RestoreSchema,
    onSubmit: ({ email }) => {
      dispatch(restorePassword(email)).then(() => {
        formik.resetForm()
      })
    },
  })

  return (
    <div className='security'>
      <div className='security__title'>Восстановить пароль</div>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          name='email'
          type='text'
          label='Логин (e-mail)'
          variant='outlined'
          fullWidth={true}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <div className='security__actions center'>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            fullWidth={false}
            size='large'
          >
            Отправить
          </Button>
        </div>
        <div className='security__links center'>
          <LinkComponent to='sign-in'>Выйти</LinkComponent>
        </div>
      </form>
    </div>
  )
}
