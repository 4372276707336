import React from 'react'
import { useHistory } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { useFormik } from 'formik'
import * as yup from 'yup'
import omit from 'lodash/omit'

import { Button, TextField } from '@material-ui/core'

import { useAppDispatch } from 'src/store'
import { signUp } from 'src/store/feature/user'

import { ValidationHelper } from 'src/utils/validation/validation'

import { AUTH_PAGES } from 'src/constants/routes'
import { LinkComponent } from 'src/components/link'

import '../../auth.scss'

const SUPPLIER_ROLE = 'Поставщик'

const registrationSchema = yup.object().shape({
  fullName: yup
    .string()
    .trim()
    .required('Обязательное поле')
    .min(3, 'Не менее 3-х символов'),
  username: yup.string().trim().required('Обязательное поле').test({
    name: 'username',
    message: 'Логин должен быть корректным email адресом',
    test: ValidationHelper.testEmail,
  }),
  password: yup
    .string()
    .trim()
    .required('Обязательное поле')
    .min(10, 'Пароль не может быть менее 10 символов')
    .test({
      name: 'password',
      message:
        'Пароль должен содержать цифру, символ,' +
        'строчные и заглавные латинские буквы',
      test: ValidationHelper.testPassword,
    }),
  confirmPassword: yup
    .string()
    .trim()
    .required('Обязательное поле')
    .oneOf([yup.ref('password')], 'Пароли не совпадают'),
  inn: yup.string().trim().required('Обязательное поле').test({
    name: 'inn',
    message: 'ИНН имеет не корректный формат',
    test: ValidationHelper.testInn,
  }),
  kpp: yup.string().trim().required('Обязательное поле').test({
    name: 'kpp',
    message: 'КПП имеет не корректный формат',
    test: ValidationHelper.testKpp,
  }),
  company: yup.string().trim().required('Обязательное поле'),
})

export const Registration: React.FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const formik = useFormik({
    initialValues: {
      fullName: '',
      username: '',
      password: '',
      confirmPassword: '',
      inn: '',
      kpp: '',
      company: '',
      role: SUPPLIER_ROLE,
    },
    validationSchema: registrationSchema,
    onSubmit: values => {
      dispatch(signUp(omit(values, 'confirmPassword'))).then(res => {
        if (res.meta.requestStatus === 'rejected') {
          return
        }

        toastr.success('', 'Пользователь зарегистрирован! Ожидайте активации')
        history.push(AUTH_PAGES.LOGIN)
      })
    },
  })

  return (
    <div className='security'>
      <div className='security__title'>Регистрация</div>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          name='fullName'
          type='text'
          label='ФИО'
          variant='outlined'
          fullWidth={true}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.fullName && Boolean(formik.errors.fullName)}
          helperText={formik.touched.fullName && formik.errors.fullName}
        />
        <TextField
          name='username'
          type='text'
          label='Логин (e-mail)'
          variant='outlined'
          fullWidth={true}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />
        <TextField
          name='password'
          type='password'
          label='Пароль'
          variant='outlined'
          fullWidth={true}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <TextField
          name='confirmPassword'
          type='password'
          label='Подтверждение пароля'
          variant='outlined'
          fullWidth={true}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
        />
        <TextField
          name='company'
          type='text'
          label='Название организации'
          variant='outlined'
          fullWidth={true}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.company && Boolean(formik.errors.company)}
          helperText={formik.touched.company && formik.errors.company}
        />
        <TextField
          name='inn'
          type='text'
          label='ИНН'
          variant='outlined'
          fullWidth={true}
          onPaste={e => {
            const text = e.clipboardData.getData('Text')
            if (/\D/g.test(text)) {
              e.preventDefault()
            }
          }}
          onKeyPress={e => {
            const numberValue = e.key.replace(/\D/g, '')

            if (!numberValue) {
              e.preventDefault()
            }
          }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.inn && Boolean(formik.errors.inn)}
          helperText={formik.touched.inn && formik.errors.inn}
          inputProps={{ maxlength: 12 }}
        />
        <TextField
          name='kpp'
          type='text'
          label='КПП'
          variant='outlined'
          fullWidth={true}
          onPaste={e => {
            const text = e.clipboardData.getData('Text')
            if (/\D/g.test(text)) {
              e.preventDefault()
            }
          }}
          onKeyPress={e => {
            const numberValue = e.key.replace(/\D/g, '')

            if (!numberValue) {
              e.preventDefault()
            }
          }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.kpp && Boolean(formik.errors.kpp)}
          helperText={formik.touched.kpp && formik.errors.kpp}
          inputProps={{ maxlength: 9 }}
        />
        <div className='security__actions'>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            fullWidth={true}
            size='large'
          >
            Зарегистрироваться
          </Button>
        </div>
        <div className='security__links center'>
          <LinkComponent to='sign-in'>Выйти</LinkComponent>
        </div>
      </form>
    </div>
  )
}
