import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiClient } from 'src/services/apiClient'

import { WithData } from '../../../@types/api'

import { UploadAttachment } from './types'

export const uploadAttachment = createAsyncThunk<
  WithData<UploadAttachment[]>,
  File[]
>('attachment/uploadAttachment', async (files, { rejectWithValue }) => {
  const formData = new FormData()
  files.forEach(file => {
    formData.append('files', file)
  })
  const [err, res] = await apiClient.post<File[], WithData<UploadAttachment[]>>(
    {
      path: '/attachment/upload',
      body: files,
    },
  )

  if (err) {
    throw rejectWithValue(err)
  }

  return res.data
})

export const deleteAttachment = createAsyncThunk<number, string | number>(
  'attachment/deleteAttachment',
  async (id, { rejectWithValue }) => {
    const [err] = await apiClient.delete({
      path: `/attachment/${id}`,
    })

    if (err) {
      throw rejectWithValue(err)
    }
    return Number(id)
  },
)
