import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { AUTH_PAGES } from 'src/constants/routes'
import { ReactComponent as LogoIcon } from 'src/assets/svg/logo.svg'

import { Login } from './pages/login'
import { Registration } from './pages/registration'
import { Restore } from './pages/restore'
import { Recovery } from './pages/recovery'

import './auth.scss'

export const Auth: React.FC = () => (
  <div className='auth-page'>
    <div className='auth-page__contains'>
      <div className='auth-page__logo'>
        <LogoIcon className='auth-page__logo-icon' />
        <span className='auth-page__logo-text'>Личный кабинет</span>
      </div>
      <Switch>
        <Route path={AUTH_PAGES.LOGIN} component={Login} />
        <Route path={AUTH_PAGES.SIGN_UP} component={Registration} />
        <Route path={AUTH_PAGES.RESTORE} component={Restore} />
        <Route path={AUTH_PAGES.RECOVERY} component={Recovery} />
        <Redirect to={AUTH_PAGES.LOGIN} />
      </Switch>
    </div>
  </div>
)
