import React from 'react'
import classnames from 'classnames'

import './header.scss'

export type HeaderProps = {
  className?: string
}

export const Header: React.FC<HeaderProps> = ({ className, children }) => (
  <div className={classnames('headline', className)}>
    <ul className='nav-list'>{children}</ul>
  </div>
)
