import { reducer as toastrReducer } from 'react-redux-toastr'

import {
  configureStore,
  ThunkAction,
  Action,
  MiddlewareAPI,
  isRejectedWithValue,
} from '@reduxjs/toolkit'

import { AnyAction, Dispatch } from 'redux'

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import user from './feature/user/user-slice'
import tenderTasks from './feature/tender-tasks/tender-tasks-slice'
import grandTenderTaskDetail from './feature/grand-tender-task-detail/grand-tender-task-detail-slice'
import provider from './feature/provider/provider-slice'
import agreements from './feature/agreements/agreements-slice'
import agreementDetail from './feature/agreement-detail/agreement-detail-slice'
import tenderTaskDetail from './feature/tender-task-detail/tender-task-detail-slice'
import attachment from './feature/attachment/attachment-slice'
import { maintenance } from './feature/maintenance/maintenance.slice'
import { resolveExceptionError } from './feature/error/resolveExceptionError'

export interface ErrorPayload {
  message: string
  status: number
}
export type AsyncThunkConfig = {
  rejectValue: ErrorPayload
}
// TODO move to application start
const SHOW_VALIDATION_ERRORS = true

const errorInterceptor =
  ({ dispatch }: MiddlewareAPI<Dispatch<any>, any>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    if (isRejectedWithValue(action)) {
      const payload = action.payload as Error

      dispatch(
        resolveExceptionError({
          error: payload,
          showValidationErr: SHOW_VALIDATION_ERRORS,
        }),
      )
    }
    return next(action)
  }

export const store = configureStore({
  reducer: {
    toastr: toastrReducer,
    attachment,
    user,
    tenderTasks,
    provider,
    grandTenderTaskDetail,
    agreements,
    agreementDetail,
    tenderTaskDetail,
    maintenance,
  },

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(errorInterceptor),
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export const useAppDispatch = <T = AppDispatch>(): T => useDispatch<T>()

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export type TypedThunkAction = (
  dispatch: MiddlewareAPI<Dispatch<any>, any>,
  getState: () => RootState,
) => any | Promise<any>
