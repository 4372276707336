import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../'

export const getUserSelector = (state: RootState): RootState['user'] =>
  state.user

export const getIsAuthSelector = createSelector(
  getUserSelector,
  user => user.isAuth,
)

export const getUserDetailSelector = createSelector(
  getUserSelector,
  user => user.detail,
)

export const getUserCompanySelector = createSelector(
  getUserDetailSelector,
  userDetail => userDetail.company,
)
