import { GridSortItem } from '@material-ui/x-grid'

import { TendersTaskType } from '../constants/routes'

export type Key = string | number

export type JWTToken = {
  access: string
  refresh: string
}

export type TSignIn = {
  username: string
  password: string
}

export type WithData<T> = {
  data: T
  status: string
}

export type ProviderDetail = {
  actualAddress: string
  address: string
  attachments: []
  comment: null
  data: unknown[]
  email: string
  id: number
  inn: string
  kpp: string
  name: string
  phone: string
  representative: string
  specialisation: string
  tenderTasks: unknown[]
}

export type TenderTask = {
  id: number
  lotId: number
  lotName: string
  tenderTaskNumber: number
  subject: string
  text: string
  sendDateTime: string
  module: string
  countOfProviders: null
  countOfAttachments: number
  specializations: string[]
}

export type Mails = {
  content: TenderTask[]
  totalElements: number
  pageable: {
    sort: { sorted: boolean; unsorted: boolean; empty: boolean }
    pageNumber: number
    pageSize: number
    offset: number
    paged: boolean
    unpaged: boolean
  }
  last: boolean
  totalPages: number
  sort: { sorted: boolean; unsorted: boolean; empty: boolean }
  first: boolean
  number: number
  numberOfElements: number
  size: number
  empty: boolean
}

export enum ModuleTypes {
  grandtender = 'grandtender',
  tender = 'tender',
}

export type TableParams = {
  size: number
  page: number
  sort: GridSortItem
  filters: unknown[]
}

export type getMailsParams = TableParams & { type: TendersTaskType }

export enum BaseColumnTitle {
  email = 'email',
  legalAddress = 'юридический адрес',
  name = 'название',
  nameOfContactPerson = 'фио контактного лица',
  tel = 'Телефон',
  specialization = 'специализация',
  sbConclusion = 'заключение сб',
  sbDate = 'дата заключения сб',
  actualAddress = 'фактический адрес',
  inn = 'инн',
  kpp = 'кпп',
}

export enum EColumnType {
  STRING = 'string',
  PHONE = 'phone',
  BOOLEAN = 'boolean',
  DATE = 'date',
  INTEGER = 'integer',
  DOUBLE = 'double',
  CURRENCY = 'currency',
  TENDER_CURRENCY = 'money',
  LIST = 'list',
  TREE_LIST = 'tree_list',
}

export type TKeyValuePair = {
  /**
   * Ключ столбца
   */
  key: Key
  /**
   * Значение столбца
   */
  value: string
}

export type TColumn = {
  /**
   * Системный идентификатор столбца
   */
  key: Key
  /**
   * Заголовок столбца
   */
  title: string
  /**
   * Тип столбца
   */
  type: EColumnType
  /**
   * Обязательный
   */
  required: boolean
  /**
   * Признак того, является ли столбец базовым
   */
  base: boolean
  /**
   * Скрывать столбец из табличного представления
   */
  hidden: boolean
  /**
   * Возможность редактировать столбец в записи
   */
  editable: boolean
  /**
   * Единица измерения
   */
  unit?: string
  /**
   * Раздел
   */
  section?: TKeyValuePair | null
}

export type TRow = {
  /**
   * ID строки
   */
  rowId: string
  /**
   * Значения по столбцам
   */
  data: TKeyValuePair[]
  /**
   * URL основной фотографии
   */
  photo?: string
  price?: string
  id?: string
}

export type ProviderInfo = {
  columns: TColumn[]
  data: TRow[]
}

export type UserDetail = {
  username: string
  fullName: string
  company: string | null
}

export type TPosition = {
  category: {
    key: number
    value: string
  }
  categoryPath: string | null
  columns: TColumn[]
  data: TRow[]
  pagination: {
    rowsPerPage: number
    page: number
    total: number
  }
}

export type TSpecialization = {
  id: Key
  name: string
}

export type TTenderConditionValues = {
  providerId: number
  providerName: string
  value: string
}

export type TTenderCondition = {
  editable: boolean
  name: string
  tenderConditionId: number
  values: TTenderConditionValues[]
}

export type SuppliersData = {
  data: TKeyValuePair[]
  hasAgreement: boolean
  name: string
  positionId: Key
  price: string
  rowId: null
}

export type TLot = {
  id: number
  name: string
  description: string
  hasAgreement: boolean
  note: string
  status: string
  positions: TPosition[]
  specialization: TSpecialization[]
  tenderConditions: TTenderCondition[]
  suppliers: {
    columns: TColumn[]
    data: SuppliersData[]
  }
}

export type IncomingAttachment = {
  author: null
  bucket: string
  docType: null
  fileName: string
  id: number
  path: null
  sendFile: null
  url: string
}

export type OutgoingAttachment = IncomingAttachment

export type GrandTenderTaskDetail = {
  id: number
  categoryPath: string
  name: string
  status: string
  info: {
    columns: TColumn[]
    data: TRow[]
  }
  lots: TLot[]
  answerSent: boolean
  contactEmail: string
  contacts: string
  customer: string
  endDate: number
  startDate: number
  incomingAttachments: IncomingAttachment[]
  outgoingAttachments: OutgoingAttachment[]
}

export type GrandTenderTaskDetailParams = {
  tab: TendersTaskType
  module: string
  tenderId: string
  lotId: string
  omgId: string
}

export type createNewPasswordParams = {
  token: string
  passwordNew: string
}
