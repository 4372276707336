import { createSlice } from '@reduxjs/toolkit'

import { AttachmentState } from './types'

import { deleteAttachment, uploadAttachment } from './actions'

const initialState: AttachmentState = {
  isLoading: false,
  upload: [],
}

const attachment = createSlice({
  name: 'attachment',
  initialState,
  reducers: {
    resetUploadAttachment: state => {
      state.upload = []
    },
  },
  extraReducers: builder => {
    builder.addCase(uploadAttachment.pending, state => {
      state.isLoading = true
    })
    builder.addCase(
      uploadAttachment.fulfilled,
      (state, { payload: { data } }) => {
        state.upload.push(...data)
        state.isLoading = false
      },
    )
    builder.addCase(uploadAttachment.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(deleteAttachment.fulfilled, (state, { payload }) => {
      state.upload = state.upload.filter(el => el.id !== payload)
    })
  },
})

export const { resetUploadAttachment } = attachment.actions

export default attachment.reducer
