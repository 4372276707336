import { ApiException } from 'src/services/apiService/exceptions/api.exception'
import { UnauthorizedException } from 'src/services/apiService/exceptions/unauthorized.exception'
import { toastr } from 'react-redux-toastr'

import { ServerUnavailableException } from 'src/services/apiService/exceptions/serverUnavailable.exception'

import { EmptyTokenException } from 'src/services/apiService/exceptions/emptyToken.exception'

import { ForbiddenException } from 'src/services/apiService/exceptions/forbidden.exception'

import { ErrorException } from 'src/services/apiService/exceptions/error.exception'

import { Dispatch } from '@reduxjs/toolkit'

import { enableMaintenance } from '../maintenance/maintenance.slice'

import { logout } from '../user/user-slice'

import { ValidationErrorException } from '../../../services/apiService/exceptions/validationError.exception'
import { refreshToken } from '../user'

export function resolveExceptionError({
  error,
  showValidationErr,
}: {
  error: Error
  showValidationErr?: boolean
}) {
  return (dispatch: Dispatch<any>) => {
    if (error instanceof ValidationErrorException) {
      if (showValidationErr) {
        console.error(error)
        toastr.error(
          error.name,
          `path: ${
            error.errorData?.path
          }; error: ${error.errorData?.errors.join(';')}`,
        )
      }
      return
    }

    if (error instanceof UnauthorizedException) {
      dispatch(refreshToken())
      return
    }

    if (error instanceof ServerUnavailableException) {
      dispatch(enableMaintenance())
      return
    }

    if (error instanceof EmptyTokenException) {
      dispatch(logout())
      return
    }

    if (error instanceof ForbiddenException) {
      toastr.error(error.name, 'У вас нет доступа к текущей странице')
      // Redirect to 403 page
      return
    }

    if (error instanceof ErrorException) {
      const { status, message } = error

      if (!status) {
        throw new Error(`Unknown status ${status} with message ${message} `)
      }

      switch (status) {
        case 400:
        case 500:
          toastr.error(`${status}`, message)
          break

        case 401:
          dispatch(logout())
          break

        case 403:
          // TODO redirect to 403 page
          // dispatch(enable403())
          break

        case 503:
          dispatch(enableMaintenance())
          break

        case 551:
          dispatch(refreshToken())
          break

        default:
          throw new Error(
            `Unhandled error with code: ${status}, message: ${message}`,
          )
      }
      return
    }

    if (error instanceof ApiException) {
      console.error(error)
      toastr.error(
        'Unknown Api error',
        `StatusCode: ${error.statusCode}; Url:${error.req.url || ''} `,
      )
      return
    }

    console.error(error)
    toastr.error('Unhandled Error', error.name)
  }
}
