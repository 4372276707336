import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, Link } from 'react-router-dom'

import { IconButton, Tooltip } from '@material-ui/core'
import NotificationsIcon from '@material-ui/icons/Notifications'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import { useAppDispatch } from 'src/store'
import { getUserCompanySelector, logout } from 'src/store/feature/user'

import { ReactComponent as LogoIcon } from 'src/assets/svg/logo.svg'
import { ReactComponent as TenderIcon } from 'src/assets/svg/tender.svg'
import { ReactComponent as ProfileIcon } from 'src/assets/svg/profile.svg'
import { ReactComponent as LogoutIcon } from 'src/assets/svg/logout.svg'
import { ReactComponent as AgreementIcon } from 'src/assets/svg/agreement.svg'
import { useNavbar } from 'src/common/useNavbar'
import { getCurrentEnvModulesData } from 'src/common/getCurrentEnvModulesData'

import { APP_PAGES } from '../../constants/routes'
import { Alert } from '../modals/alert/alert'

import './app-header.scss'

const emptyNavModules = [
  [{ id: 0, name: '', url: '' }],
  [{ id: 0, name: '', url: '' }],
]

export const AppHeader: React.FC = () => {
  const dispatch = useAppDispatch()
  const company = useSelector(getUserCompanySelector)
  const [openLogoutModal, setOpenLogoutModal] = useState<boolean>(false)
  const links =
    window.SPA_LINKS_CONFIG || process.env.REACT_APP_SPA_LINKS_CONFIG
  const navModules = links ? getCurrentEnvModulesData(links) : null
  const { Navbar } = useNavbar({
    navModules: navModules || emptyNavModules,
  })

  const handleOpenLogoutModal = () => setOpenLogoutModal(true)

  const handleCloseLogoutModal = () => setOpenLogoutModal(false)

  const handleConfirmLogoutModal = () => {
    setOpenLogoutModal(false)
    dispatch(logout())
  }

  return (
    <>
      <header className='app-header'>
        <div className='app-header-left-side'>
          <div className='logo'>
            <LogoIcon />
            <div className='logo-text'>Личный кабинет</div>
            {Navbar}
          </div>
          <nav className='navigation'>
            <NavLink className='navigation-link' to={APP_PAGES.TENDER_TASKS}>
              <div>
                <TenderIcon />
              </div>
              <div className='navigation-link-text'>Тендерные задания</div>
            </NavLink>
            <NavLink className='navigation-link' to={APP_PAGES.AGREEMENTS}>
              <div>
                <AgreementIcon />
              </div>
              <div className='navigation-link-text'>Соглашения</div>
            </NavLink>
            <NavLink className='navigation-link' to={APP_PAGES.COMPANY_PROFILE}>
              <div>
                <ProfileIcon />
              </div>
              <div className='navigation-link-text'>Профиль компании</div>
            </NavLink>
          </nav>
        </div>
        <div className='app-header-right-side'>
          <div className='app-header-user-info'>
            <div className='app-header-user-info-title'>{`${company}`}</div>
            <div className='app-header-user-info-desc'>Поставщик</div>
          </div>
          <div>
            <IconButton size='small'>
              <Tooltip title='Смена пароля'>
                <Link to={APP_PAGES.CHANGE_PASSWORD}>
                  <AccountCircleIcon
                    style={{ color: 'white', fontSize: '24px' }}
                  />
                </Link>
              </Tooltip>
            </IconButton>
          </div>
          <div className='app-header-contact-support'>
            <Tooltip title='Техподдержка'>
              <a
                href='https://gmini.freshdesk.com/a/tickets/new'
                target='_blank'
                rel='noreferrer'
              >
                <ContactSupportIcon
                  style={{ color: 'white', fontSize: '24px' }}
                />
              </a>
            </Tooltip>
          </div>
          <div className='app-header-notification'>
            <IconButton size='small'>
              <Tooltip title='Уведомления'>
                <NotificationsIcon
                  style={{ color: 'white', fontSize: '24px' }}
                />
              </Tooltip>
            </IconButton>
          </div>
          <div className='app-header-logout'>
            <IconButton size='small' onClick={handleOpenLogoutModal}>
              <Tooltip title='Завершить работу'>
                <LogoutIcon />
              </Tooltip>
            </IconButton>
          </div>
        </div>
      </header>
      <Alert
        open={openLogoutModal}
        title='Выход'
        contentText='Вы действительно хотите выйти из сессии?'
        handleClose={handleCloseLogoutModal}
        handleConfirm={handleConfirmLogoutModal}
      />
    </>
  )
}
