import { createSlice } from '@reduxjs/toolkit'
import { UserDetail } from 'src/@types/api'
import { REACT_TOKEN_AUTH_KEY } from 'src/services/constants'

import { getUserDetail } from './actions'

type AuthState = {
  isAuth: boolean
  detail: UserDetail
}

const initialState = {
  isAuth: Boolean(sessionStorage.getItem(REACT_TOKEN_AUTH_KEY)),
  detail: {},
} as AuthState

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsAuth: (state, { payload }) => {
      state.isAuth = payload
    },
    logout: state => {
      sessionStorage.removeItem(REACT_TOKEN_AUTH_KEY)
      state.isAuth = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getUserDetail.fulfilled, (state, { payload }) => {
      state.detail = payload
    })
  },
})

export const { setIsAuth, logout } = user.actions

export default user.reducer
