import React from 'react'
import { Tooltip } from '@material-ui/core'
import { GridColumnHeaderParams } from '@material-ui/x-grid'

import './tableHeader.scss'

type TTableHeader = {
  headerName: string
  params?: GridColumnHeaderParams
  className?: string
}

export const TableHeader = (params: TTableHeader) => {
  const { headerName, className } = params
  return (
    <div>
      <Tooltip title={headerName}>
        <div className={className === 'tendersDetail' ? 'tanderDetail' : ''}>
          {headerName}
        </div>
      </Tooltip>
    </div>
  )
}
