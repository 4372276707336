type ValidationErrorData = { path: string; errors: string[] }

export class ValidationErrorException extends Error {
  errorData: ValidationErrorData | null = null

  constructor(errorData: ValidationErrorData) {
    super()
    this.name = 'ValidationErrorException'
    this.errorData = errorData
  }
}
