import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { format } from 'date-fns'

import {
  getAgreementDetails,
  getAgreementProgress,
  refreshAgreementProgress,
  saveAgreementAttachment,
  removeAgreementAttachment,
} from './actions'
import {
  TAgreementDetails,
  TAgreementFilterConfig,
  TAgreementProgress,
} from './types'

export type initialStateProps = {
  isLoading: boolean
  contractData: TAgreementDetails
  progressData: TAgreementProgress
  filterConfig: Omit<TAgreementFilterConfig, 'agreementId'>
}

const initialState: initialStateProps = {
  isLoading: false,
  contractData: {
    agreementNumber: 0,
    attachmentEntities: [],
    categories: [],
    startDate: '',
    endDate: '',
    nameInspection: null,
    attachments: [],
  },
  filterConfig: {
    showCompleted: false,
    startDate: format(Date.now(), 'yyyy-MM-dd'),
    endDate: format(Date.now(), 'yyyy-MM-dd'),
  },
  progressData: {
    id: '',
    version: 0,
    categories: [],
    agreementNumber: 0,
    startDate: '',
    endDate: '',
    progressPercent: undefined,
    progressTotalDone: undefined,
    progressTotalSum: undefined,
    status: '',
    comment: '',
    totalAllFullPrice: 0,
  },
}

const agreementDetail = createSlice({
  name: 'agreementDetail',
  initialState,
  reducers: {
    resetAgreementDetail: () => initialState,
    updateFilterConfig: (
      state,
      { payload }: PayloadAction<Omit<TAgreementFilterConfig, 'agreementId'>>,
    ) => {
      state.filterConfig = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getAgreementDetails.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getAgreementDetails.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.contractData = payload.data
    })
    builder.addCase(getAgreementDetails.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(getAgreementProgress.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getAgreementProgress.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(getAgreementProgress.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.progressData = payload.data
    })
    builder.addCase(refreshAgreementProgress.pending, state => {
      state.isLoading = true
    })
    builder.addCase(refreshAgreementProgress.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(
      refreshAgreementProgress.fulfilled,
      (state, { payload }) => {
        state.isLoading = false
        state.progressData = payload.data
      },
    )
    builder.addCase(saveAgreementAttachment.pending, state => {
      state.isLoading = true
    })
    builder.addCase(saveAgreementAttachment.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.contractData.attachments.push(payload)
    })
    builder.addCase(saveAgreementAttachment.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(removeAgreementAttachment.pending, state => {
      state.isLoading = true
    })
    builder.addCase(
      removeAgreementAttachment.fulfilled,
      (state, { payload }) => {
        state.contractData.attachments = state.contractData.attachments.filter(
          attachment => attachment.id !== Number(payload),
        )
        state.isLoading = false
      },
    )
    builder.addCase(removeAgreementAttachment.rejected, state => {
      state.isLoading = false
    })
  },
})

export const { resetAgreementDetail, updateFilterConfig } =
  agreementDetail.actions

export default agreementDetail.reducer
