import { createAsyncThunk } from '@reduxjs/toolkit'

import { toastr } from 'react-redux-toastr'
import { apiClient } from 'src/services/apiClient'

import { GrandTenderTaskDetailParams, WithData } from '../../../@types/api'

import { RootState } from '../../index'

import { TTenderTaskDetail } from './types'

export const getTenderTaskDetail = createAsyncThunk<
  TTenderTaskDetail,
  GrandTenderTaskDetailParams
>(
  'tenderTaskDetail/getGrandTenderTaskDetail',
  async (params, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<WithData<TTenderTaskDetail>>({
      path: `/tenderTaskDetails/${params.module}`,
      params: { lotId: params.lotId },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    return res.data.data
  },
)

export const saveOutgoingDocuments = createAsyncThunk<
  WithData<unknown>,
  GrandTenderTaskDetailParams,
  { state: RootState }
>(
  'grandTenderTaskDetail/saveOutgoingDocuments',
  async ({ tenderId, lotId }, { getState, rejectWithValue }) => {
    const store = getState()
    const {
      tenderTaskDetail: {
        data: { outgoingAttachments },
      },
      attachment: { upload },
    } = store

    const [err, res] = await apiClient.post<any, WithData<unknown>>({
      path: '/mails/outgoing',
      body: {
        tenderId,
        lotId,
        attachments: [...outgoingAttachments, ...upload],
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    return res.data
  },
)

export const sendTenderTask = createAsyncThunk<
  unknown,
  string,
  { state: RootState }
>(
  'tenderTaskDetail/sendTenderTask',
  async (lotId: string, { rejectWithValue }) => {
    const [err, res] = await apiClient.post({
      path: `/tenderTaskDetails/send/tender?lotId=${lotId}`,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.success('', 'Тендерное задание успешно отправлено')

    return res.data
  },
)

export const saveTenderTask = createAsyncThunk<
  unknown,
  string,
  { state: RootState }
>(
  'tenderTaskDetail/saveTenderTask',
  async (lotId: string, { getState, rejectWithValue }) => {
    const {
      tenderTaskDetail: { data },
    } = getState()
    const [err] = await apiClient.put({
      path: '/tenderTaskDetails/tender',
      params: { lotId },
      body: data,
    })

    if (err) {
      throw rejectWithValue(err)
    }
  },
)
