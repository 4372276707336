import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AgreementPageTabs } from '../../../constants/routes'

import { TFilterCatalog, TInitialState } from './types'

import { getAgreementCatalog, getAgreements } from './actions'

const initialState: TInitialState = {
  isLoading: false,
  content: [],
  totalElements: 0,
  catalog: { projects: [] },
  filterCatalog: {
    project: {
      key: 'project',
      singleOperators: [{ filterCompareType: 'eq', value: null }],
    },
    object: {
      key: 'object',
      singleOperators: [{ filterCompareType: 'eq', value: null }],
    },
    discipline: {
      key: 'discipline',
      singleOperators: [{ filterCompareType: 'eq', value: null }],
    },
  },
  tableParams: {
    type: AgreementPageTabs.active,
    page: 0,
    size: 0,
    sort: { sortBy: '', direction: '' },
  },
}

const agreements = createSlice({
  name: 'agreements',
  initialState,
  reducers: {
    setTableParams: (state, action) => {
      state.tableParams = action.payload
    },
    setFilterCatalog: (state, action: PayloadAction<TFilterCatalog>) => {
      state.filterCatalog = action.payload
    },
    resetFilterCatalog: state => {
      state.filterCatalog = initialState.filterCatalog
    },
  },
  extraReducers: builder => {
    builder.addCase(getAgreements.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getAgreements.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.content = payload.data.content
      state.totalElements = payload.data.totalElements
      state.tableParams.page = payload.data.number
      state.tableParams.size = payload.data.size
    })
    builder.addCase(getAgreements.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(getAgreementCatalog.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.catalog = payload.data
    })
    builder.addCase(getAgreementCatalog.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getAgreementCatalog.rejected, state => {
      state.isLoading = false
    })
  },
})

export const { setFilterCatalog, setTableParams, resetFilterCatalog } =
  agreements.actions
export default agreements.reducer
