type RequestPayload<T> = {
  url?: string
  method?: string
  body?: T
}

type ApiExceptionProps<Req, Res> = {
  statusCode: number
  req: RequestPayload<Req>
  data: Res
}

export class ApiException<Req, Res> extends Error {
  statusCode: number
  req: RequestPayload<Req>
  data: Res
  constructor({ statusCode, req, data }: ApiExceptionProps<Req, Res>) {
    super()
    this.name = 'ApiException'
    this.statusCode = statusCode
    this.req = req
    this.data = data
  }
}
