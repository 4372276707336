import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useHistory, useParams } from 'react-router'

import { TextField } from '@material-ui/core'
import { Button } from '@ifellow/ui-library'

import { useAppDispatch } from 'src/store'
import {
  checkRecoveryToken,
  createNewPassword,
} from 'src/store/feature/user/actions'

import { ValidationHelper } from 'src/utils/validation/validation'

import { LinkComponent } from 'src/components/link'

import '../../auth.scss'

const RecoverySchema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .required('Обязательное поле')
    .min(10, 'Пароль не может быть менее 10 символов')
    .test({
      name: 'password',
      message:
        'Пароль должен содержать цифру, символ,' +
        'строчные и заглавные латинские буквы',
      test: ValidationHelper.testPassword,
    }),
  confirmPassword: yup
    .string()
    .trim()
    .required('Обязательное поле')
    .oneOf([yup.ref('password')], 'Пароли не совпадают'),
})

export const Recovery: React.FC = () => {
  const dispatch = useAppDispatch()
  const { recoveryToken } = useParams<Record<string, string>>()
  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: RecoverySchema,
    // eslint-disable-next-line
    onSubmit: (values) => {
      dispatch(
        createNewPassword({
          token: recoveryToken,
          passwordNew: values.password,
        }),
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          history.push('/')
        }
      })
    },
  })

  const [isValidToken, setIsValidToken] = useState(true)

  useEffect(() => {
    dispatch(checkRecoveryToken(recoveryToken)).then(res => {
      if (res.meta.requestStatus !== 'fulfilled') {
        setIsValidToken(false)
      }
    })
  }, [dispatch, recoveryToken])

  return (
    <div className='security'>
      <div className='security__title'>Восстановление пароля</div>
      {!isValidToken && (
        <div>Произошла ошибка! Попробуйте восстановить пароль еще раз.</div>
      )}
      {isValidToken && (
        <form onSubmit={formik.handleSubmit}>
          <TextField
            name='password'
            type='password'
            label='Введите пароль'
            variant='outlined'
            fullWidth={true}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <TextField
            name='confirmPassword'
            type='password'
            label='Подтверждение пароля'
            variant='outlined'
            fullWidth={true}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          <div className='security__actions right'>
            <Button htmlType='submit' type='primary' title='Сохранить' />
          </div>
        </form>
      )}
      {!isValidToken && (
        <div className='security__links center'>
          <LinkComponent to='/'>На главную</LinkComponent>
        </div>
      )}
    </div>
  )
}
