import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  getMails,
  getTenderTaskSelector,
  resetTenderTasks,
  setSelectedIds,
  setPrevTab,
} from 'src/store/feature/tender-tasks'
import { useAppDispatch } from 'src/store'

import { getMailsParams, TableParams } from 'src/@types/api'
import { TendersTaskType } from 'src/constants/routes'

import { Loader, XGridTable } from '@ifellow/ui-library'
import { GridSelectionModel } from '@material-ui/x-grid'

import { Header, HeaderLink } from 'src/components/header'

import { createXGridColumns } from './utils'

import './tender-tasks.scss'

export const TenderTasks: React.FC = () => {
  const dispatch = useAppDispatch()
  const {
    isLoading,
    data: tenderTasks,
    selectedIds,
    totalElements,
    prevTab,
  } = useSelector(getTenderTaskSelector)
  const { tab } = useParams<{ tab: TendersTaskType }>()
  const columns = createXGridColumns(tab)

  const handleSetChosen = (selectionModel: GridSelectionModel): void => {
    dispatch(setSelectedIds(selectionModel))
  }

  const parametersChangeHandler = useCallback(
    (params: TableParams) => {
      const mailParams: getMailsParams = {
        type: tab,
        ...params,
      }

      dispatch(getMails(mailParams)).then(() => {
        if (prevTab !== tab) {
          dispatch(setPrevTab(tab))
          dispatch(setSelectedIds([]))
          return
        }

        dispatch(setSelectedIds(selectedIds))
      })
    },
    [dispatch, tab, prevTab, selectedIds],
  )

  useEffect(
    () => () => {
      dispatch(resetTenderTasks())
    },
    [dispatch, tab],
  )

  return (
    <div className='tender-task-page'>
      <Loader visible={isLoading} />
      <Header className='tender-task-header'>
        <HeaderLink to={`${TendersTaskType.new}`} text={'Новые'} />
        <HeaderLink to={`${TendersTaskType.sent}`} text={'Отправленные'} />
      </Header>
      <XGridTable
        key={`tenders-${tab}`}
        className='tender-task-table'
        columns={columns}
        rows={tenderTasks}
        mode='server'
        pageSize={20}
        pageStart={0}
        rowsPerPageOptions={[10, 20, 50]}
        selectionModel={selectedIds}
        onSelectionModelChange={handleSetChosen}
        onParametersChange={parametersChangeHandler}
        rowCount={totalElements}
        initSortModel={[{ field: 'sendDateTime', sort: 'desc' }]}
        localStorageCache={`tenders-${tab}`}
        withSettings={true}
      />
    </div>
  )
}
