import { createSlice } from '@reduxjs/toolkit'

import { InitialState } from './types'

import { getTenderTaskDetail } from './actions'

const initialState: InitialState = {
  isLoading: false,
  data: {
    answerSent: false,
    categories: [],
    categoryPositions: [],
    incomingAttachments: [],
    outgoingAttachments: [],
    sectionWithFieldValuesResponses: [],
    tenderTask: null,
  },
}

const tenderTaskDetail = createSlice({
  name: 'tenderTaskDetail',
  initialState,
  reducers: {
    resetTenderTaskDetail: () => initialState,
    setCategoryPositions: (state, action) => {
      state.data.categoryPositions = action.payload
    },
    removeOutgoingAttachments: (state, { payload }) => {
      state.data.outgoingAttachments = state.data.outgoingAttachments.filter(
        el => Number(el.id) !== payload,
      )
    },
  },
  extraReducers: builder => {
    builder.addCase(getTenderTaskDetail.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getTenderTaskDetail.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.data = payload
    })
    builder.addCase(getTenderTaskDetail.rejected, state => {
      state.isLoading = true
    })
  },
})

export const {
  resetTenderTaskDetail,
  setCategoryPositions,
  removeOutgoingAttachments,
} = tenderTaskDetail.actions
export default tenderTaskDetail.reducer
