import { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { toastr } from 'react-redux-toastr'

import {
  createNewPasswordParams,
  JWTToken,
  TSignIn,
  UserDetail,
  WithData,
} from 'src/@types/api'

import { REACT_TOKEN_AUTH_KEY } from 'src/services/constants'

import { generateFingerprint, getLoginUrl } from 'src/services/utils'

import { apiClient } from '../../../services/apiClient'

import { logout, setIsAuth } from './user-slice'
export const LOGIN_URL = getLoginUrl()

export const login = createAsyncThunk<WithData<JWTToken>, TSignIn>(
  'user/signIn',
  async (message, { dispatch, rejectWithValue }) => {
    const fingerprint = await generateFingerprint()

    const [err, res] = await apiClient.post<TSignIn, WithData<JWTToken>>({
      path: `/login?fingerprint=${fingerprint.visitorId}`,
      body: message,
      baseUrl: LOGIN_URL,
      credentials: false,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    sessionStorage.setItem(REACT_TOKEN_AUTH_KEY, JSON.stringify(res.data.data))
    dispatch(setIsAuth(true))
    return res.data
  },
)

export const signUp = createAsyncThunk<unknown, unknown>(
  'user/signUp',
  async (data, { rejectWithValue }) => {
    const [err, res] = await apiClient.post({
      path: '/sign_up',
      baseUrl: LOGIN_URL,
      body: data,
      credentials: false,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    return res.data
  },
)

export const getUserDetail = createAsyncThunk<UserDetail, void>(
  'user/getUserDetail',
  async (_, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<UserDetail>({
      path: `/user/detail`,
      // TODO response validation

      // decoder: t.type({
      //   company: t.string,
      //   externalId: t.string,
      //   fullName: t.string,
      //   username: t.string,
      // }),
    })

    if (err) {
      throw rejectWithValue(err)
    }
    return res.data
  },
)

export type changePasswordParams = {
  passwordCurrent: string
  passwordNew: string
}

export const changePassword = createAsyncThunk<unknown, changePasswordParams>(
  'user/changePassword',
  async (values, { rejectWithValue }) => {
    const [err, res] = await apiClient.patch<changePasswordParams, unknown>({
      path: '/user',
      body: values,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.success('', 'Пароль успешно изменен')

    return res.data
  },
)

export const restorePassword = createAsyncThunk(
  'user/restorePassword',
  async (mail: string, { rejectWithValue }) => {
    const [err, res] = await apiClient.get({
      path: `/user/reset/${mail}`,
      credentials: false,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.success('', 'На почту было отправлено письмо')

    return res.data
  },
)

export const checkRecoveryToken = createAsyncThunk<
  AxiosResponse<unknown>,
  string
>('user/checkRecoveryToken', async (token, { rejectWithValue }) => {
  const [err, res] = await apiClient.get<unknown>({
    path: '/user/recovery',
    credentials: false,
    params: {
      token,
    },
  })

  if (err) {
    throw rejectWithValue(err)
  }

  return res
})

export const createNewPassword = createAsyncThunk<
  unknown,
  createNewPasswordParams
>('user/createNewPassword', async (params, { rejectWithValue }) => {
  const [err, res] = await apiClient.post({
    path: '/user/recovery/updatePassword',
    body: params,
    credentials: false,
  })

  if (err) {
    throw rejectWithValue(err)
  }
  toastr.success('', 'Пароль успешно изменен')

  return res.data
})

export const refreshToken = createAsyncThunk<unknown, void>(
  'user/refreshToken',
  async (_, { dispatch }) => {
    const fingerprint = await generateFingerprint()

    const refreshToken = JSON.parse(
      sessionStorage.getItem(REACT_TOKEN_AUTH_KEY) as string,
    ).refresh as string

    const [err, res] = await apiClient.post({
      path: '/auth/refresh',
      baseUrl: LOGIN_URL,
      body: {
        refresh: refreshToken,
        fingerprint: fingerprint.visitorId,
      },
    })

    if (err) {
      dispatch(logout())
    }

    return res.data
  },
)
