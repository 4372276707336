import React, { Suspense } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import ReduxToastr from 'react-redux-toastr'

import { StylesProvider, createGenerateClassName } from '@material-ui/core'

import { SuspenseFallback } from './utils/suspense-fallback'

import { store } from './store'
import { App } from './app'

import './styles/base.scss'

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
})

render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<SuspenseFallback />}>
        <Router>
          <StylesProvider injectFirst generateClassName={generateClassName}>
            <App />
          </StylesProvider>
          <ReduxToastr
            timeOut={5000}
            newestOnTop={true}
            preventDuplicates={false}
            position='top-center'
            transitionIn='fadeIn'
            transitionOut='fadeOut'
            progressBar={false}
            closeOnToastrClick={true}
          />
        </Router>
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
