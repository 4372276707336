import { createAsyncThunk } from '@reduxjs/toolkit'
import { WithData } from 'src/@types/api'
import { apiClient } from 'src/services/apiClient'

import { UploadAttachment } from '../attachment/types'

import {
  TAgreementDetails,
  TAgreementProgress,
  TFieldInspection,
  TAgreementFilterConfig,
} from './types'

export const getAgreementDetails = createAsyncThunk<
  WithData<TAgreementDetails>,
  string
>('agreementDetails/getDetails', async (agreementId, { rejectWithValue }) => {
  const [err, res] = await apiClient.get<WithData<TAgreementDetails>>({
    path: `/agreements/${agreementId}`,
  })

  if (err) {
    throw rejectWithValue(err)
  }

  return res.data
})

export const getAgreementProgress = createAsyncThunk<
  WithData<TAgreementProgress>,
  string
>('agreementDetails/getProgress', async (agreementId, { rejectWithValue }) => {
  const [err, res] = await apiClient.get<WithData<TAgreementProgress>>({
    path: `/agreements/${agreementId}/progress`,
  })

  if (err) {
    throw rejectWithValue(err)
  }

  return res.data
})

export const refreshAgreementProgress = createAsyncThunk<
  WithData<TAgreementProgress>,
  [string, string, string]
>(
  'agreementDetails/refreshProgress',
  async ([agreementId, startDate, endDate], { rejectWithValue }) => {
    const [err, res] = await apiClient.post<any, WithData<TAgreementProgress>>({
      path: `/agreements/${agreementId}/progress/refresh`,
      body: {
        startDate,
        endDate,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    return res.data
  },
)

export const exportAgreementKS = createAsyncThunk<void, TAgreementFilterConfig>(
  'agreements/exportAgreementKS',
  async (
    { startDate, showCompleted, agreementId, endDate },
    { rejectWithValue },
  ) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const [err, res] = await apiClient.get<Blob>({
        path: `/agreements/${agreementId}/export/ks2?show-completed=${showCompleted}&from=${startDate}&to=${endDate}`,
        responseType: 'blob',
      })

      if (err) {
        throw rejectWithValue(err)
      }
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: 'xlsx' }),
      )

      const contentDisposition = res.headers['content-disposition']
      const [, filename] = String(contentDisposition).split(/[:;,]/)
      const decodeFilename = decodeURI(filename)
      const normalizeFilename = decodeFilename.slice(18)

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${normalizeFilename}`)
      link.click()

      URL.revokeObjectURL(link.href)
    } catch (e) {
      throw e
    }
  },
)

export const getAgreementFieldInspection = createAsyncThunk<
  TFieldInspection,
  string
>('agreements/getAgreementFieldInspection', async (id, { rejectWithValue }) => {
  const [err, res] = await apiClient.get<WithData<TFieldInspection>>({
    path: `/agreements/${id}/field-inspection`,
  })

  if (err) {
    throw rejectWithValue(err)
  }

  return res.data.data
})

export const saveAgreementAttachment = createAsyncThunk<
  UploadAttachment,
  {
    agreementId: string
    file: File[]
  }
>(
  'agreementDetails/saveAgreementAttachment',
  async (data, { rejectWithValue }) => {
    const formData = new FormData()
    data.file.forEach(file => {
      formData.append('file', file)
    })
    formData.append('agreementId', data.agreementId)
    formData.append('type', 'string')

    const [err, res] = await apiClient.post<
      FormData,
      WithData<UploadAttachment>
    >({
      path: '/agreements/attachments/save',
      body: formData,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    return res.data.data
  },
)

export const removeAgreementAttachment = createAsyncThunk<string, string>(
  'agreementDetails/removeAgreementAttachment',
  async (id, { rejectWithValue }) => {
    const [err] = await apiClient.delete({
      path: `/agreements/attachments/${id}`,
    })

    if (err) {
      throw rejectWithValue(err)
    }
    return id
  },
)
