import { createAsyncThunk } from '@reduxjs/toolkit'
import { toastr } from 'react-redux-toastr'

import {
  GrandTenderTaskDetail,
  GrandTenderTaskDetailParams,
  WithData,
} from 'src/@types/api'
import { apiClient } from 'src/services/apiClient'

import { RootState } from '../../'

export const getGrandTenderTaskDetail = createAsyncThunk<
  WithData<GrandTenderTaskDetail>,
  GrandTenderTaskDetailParams
>('grand-tender-task-detail', async (params, { rejectWithValue }) => {
  const [err, res] = await apiClient.get<WithData<GrandTenderTaskDetail>>({
    path: `/tenderTaskDetails/${params.module}`,
    params: { lotId: params.lotId },
  })

  if (err) {
    throw rejectWithValue(err)
  }
  return res.data
})

export const saveTenderTaskDetail = createAsyncThunk<
  unknown,
  string,
  { state: RootState }
>(
  'save-tender-task-by-lot-id',
  async (lotId: string, { getState, rejectWithValue }) => {
    const {
      grandTenderTaskDetail: { data },
    } = getState()
    const [err, res] = await apiClient.put({
      path: '/tenderTaskDetails',
      params: {
        lotId,
      },
      body: data,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    return res.data
  },
)

export const sendGrandTenderTask = createAsyncThunk<
  unknown,
  string,
  { state: RootState }
>(
  'grandTenderTaskDetail/sendGrandTenderTask',
  async (lotId: string, { dispatch, rejectWithValue }) => {
    await dispatch(saveTenderTaskDetail(lotId))

    const [err, res] = await apiClient.post({
      path: '/tenderTaskDetails/send/grandtender',
      params: { lotId },
    })
    if (err) {
      throw rejectWithValue(err)
    }

    toastr.success('', 'Тендерное задание успешно отправлено')

    return res.data
  },
)

export const saveOutgoingDocuments = createAsyncThunk<
  WithData<unknown>,
  GrandTenderTaskDetailParams,
  { state: RootState }
>(
  'grandTenderTaskDetail/saveOutgoingDocuments',
  async ({ tenderId, lotId }, { getState, rejectWithValue }) => {
    const store = getState()
    const {
      grandTenderTaskDetail: {
        data: { outgoingAttachments },
      },
      attachment: { upload },
    } = store

    const [err, res] = await apiClient.post<any, WithData<unknown>>({
      path: '/mails/outgoing',
      body: {
        tenderId,
        lotId,
        //TODO разобраться с типами
        attachments: [...outgoingAttachments, ...upload],
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }
    return res.data
  },
)
