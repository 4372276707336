import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TenderTask } from 'src/@types/api'
import { TendersTaskType } from 'src/constants/routes'

import { getMails } from './actions'

export type initialStateProps = {
  error: null
  isLoading: boolean
  data: TenderTask[]
  selectedIds: number[]
  number: number
  totalElements: number
  prevTab: TendersTaskType | null
}

const initialState: initialStateProps = {
  error: null,
  isLoading: false,
  data: [],
  selectedIds: [],
  number: 0,
  totalElements: 0,
  prevTab: null,
}

const tenderTasks = createSlice({
  name: 'tenderTasks',
  initialState,
  reducers: {
    resetTenderTasks: () => initialState,
    setSelectedIds: (state, { payload }) => ({
      ...state,
      selectedIds: payload,
    }),
    setPrevTab: (state, action: PayloadAction<TendersTaskType>) => {
      state.prevTab = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getMails.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getMails.fulfilled, (state, { payload }) => {
      if (payload && payload.data) {
        state.data = payload.data.content
        state.totalElements = payload.data.totalElements
        state.number = payload.data.number
      }
      state.isLoading = false
    })
    builder.addCase(getMails.rejected, (state, action) => {
      state.isLoading = false
    })
  },
})

export const { resetTenderTasks, setSelectedIds, setPrevTab } =
  tenderTasks.actions

export default tenderTasks.reducer
