import { generatePath } from 'react-router'

export const APP_PAGES = {
  ROOT: '/',
  COMPANY_PROFILE: '/company-profile',
  TENDER_TASKS: '/tender-tasks',
  TENDER_TASK_TAB: '/tender-tasks/:tab',
  NOT_FOUND: '/not-found',
  TENDER_TASK_DETAIL:
    '/:tab/:module/tender-task/:tenderId/lot/:lotId/detail/:omgId',
  CHANGE_PASSWORD: '/change-password',
  AGREEMENTS: '/agreements',
  AGREEMENT_TAB: '/agreements/:tab',
  AGREEMENT_DETAIL: '/agreement/:agreementId/:number/detail/:type',
}

export const AUTH_PAGES = {
  ROOT: '/',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  RESTORE: '/restore',
  RECOVERY: '/recovery/:recoveryToken',
}

export enum TendersTaskType {
  new = 'new',
  sent = 'sent',
}

export enum AgreementPageTabs {
  active = 'active',
  completed = 'completed',
}

export enum AgreementDetailTabs {
  contract = 'contract',
  performance = 'performance',
}

export const TENDER_TASK_START_PAGE = generatePath(APP_PAGES.TENDER_TASK_TAB, {
  tab: TendersTaskType.new,
})

export const AGREEMENT_START_PATH = generatePath(APP_PAGES.AGREEMENT_TAB, {
  tab: AgreementPageTabs.active,
})
