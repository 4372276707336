import FingerprintJS, { GetResult } from '@fingerprintjs/fingerprintjs'

export const generateFingerprint = async (): Promise<GetResult> => {
  const fingerprint = await FingerprintJS.load()

  return fingerprint.get()
}

export const getBackendUrl = (): string => '/api/lk/v1'
export const getLoginUrl = (): string =>
  window.SPA_BACKEND_URL || String(process.env.REACT_APP_BACKEND_URL)

export const getFmUrl = (): string =>
  window.SPA_FM_URL || String(process.env.REACT_APP_FM_URL)
