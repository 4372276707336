import { createAsyncThunk } from '@reduxjs/toolkit'
import { Mails, getMailsParams, WithData } from 'src/@types/api'
import { TendersTaskType } from 'src/constants/routes'
import { apiClient } from 'src/services/apiClient'

type getMailsReq = {
  page: number
  size: number
  sort: {
    sortBy: string
    direction?: string | null
  }
}

export const getMails = createAsyncThunk<WithData<Mails>, getMailsParams>(
  'tenderTasks/getMails',
  async (caches, { rejectWithValue }) => {
    const urls = {
      [TendersTaskType.new]: `/mails/incoming`,
      [TendersTaskType.sent]: `/mails/answered`,
    }

    const [err, res] = await apiClient.post<getMailsReq, WithData<Mails>>({
      path: urls[caches.type],
      body: {
        page: caches.page,
        size: caches.size,
        sort: {
          sortBy: caches.sort.field,
          direction: caches.sort.sort,
        },
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    return res.data
  },
)
