import { createSlice } from '@reduxjs/toolkit'
import { GrandTenderTaskDetail } from 'src/@types/api'

import {
  getGrandTenderTaskDetail,
  saveTenderTaskDetail,
  sendGrandTenderTask,
  saveOutgoingDocuments,
} from './actions'

export type initialStateProps = {
  isLoading: boolean
  data: GrandTenderTaskDetail
  needRenderData: boolean
}

const initialState: initialStateProps = {
  isLoading: false,
  data: {} as GrandTenderTaskDetail,
  needRenderData: false,
}

const tenderTaskDetail = createSlice({
  name: 'grandTenderTaskDetail',
  initialState,
  reducers: {
    resetGrandTenderTaskDetail: () => initialState,
    setData: (state, { payload }) => {
      state.data.lots = payload.lots
    },
    removeOutgoingAttachments: (state, { payload }) => {
      state.data.outgoingAttachments = state.data.outgoingAttachments.filter(
        el => Number(el.id) !== payload,
      )
    },
    setNeedRenderData: (state, { payload }) => {
      state.needRenderData = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(getGrandTenderTaskDetail.pending, state => {
      state.isLoading = true
    })
    builder.addCase(
      getGrandTenderTaskDetail.fulfilled,
      (state, { payload }) => {
        state.isLoading = false
        state.data = payload.data
        state.needRenderData = true
      },
    )
    builder.addCase(getGrandTenderTaskDetail.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(saveTenderTaskDetail.pending, state => {
      state.isLoading = true
    })
    builder.addCase(saveTenderTaskDetail.fulfilled, state => {
      state.isLoading = false
      state.needRenderData = true
    })
    builder.addCase(saveTenderTaskDetail.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(sendGrandTenderTask.pending, state => {
      state.isLoading = true
      state.data.answerSent = true
    })
    builder.addCase(sendGrandTenderTask.fulfilled, state => {
      state.isLoading = false
      state.data.answerSent = false
    })
    builder.addCase(sendGrandTenderTask.rejected, state => {
      state.isLoading = false
      state.data.answerSent = false
    })
    builder.addCase(saveOutgoingDocuments.pending, state => {
      state.isLoading = true
    })
    builder.addCase(saveOutgoingDocuments.fulfilled, state => {
      state.isLoading = false
    })
    builder.addCase(saveOutgoingDocuments.rejected, state => {
      state.isLoading = false
    })
  },
})

export const {
  resetGrandTenderTaskDetail,
  setData,
  removeOutgoingAttachments,
  setNeedRenderData,
} = tenderTaskDetail.actions

export default tenderTaskDetail.reducer
