import React from 'react'
import { NavLink } from 'react-router-dom'
import Button from '@material-ui/core/Button'

import './header-link.scss'

export type HeaderLinkProps = {
  text: string
  to?: string
  onClick?: () => void
}

export const HeaderLink: React.FC<HeaderLinkProps> = ({
  text,
  to,
  onClick,
}) => (
  <li className='nav-list-item'>
    {to ? (
      <NavLink className='nav-list-link' to={to}>
        <Button className='nav-list-button' color='primary' onClick={onClick}>
          {text}
        </Button>
      </NavLink>
    ) : (
      <Button className='nav-list-button' color='primary' onClick={onClick}>
        {text}
      </Button>
    )}
  </li>
)
