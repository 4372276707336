import React from 'react'
import { Link } from 'react-router-dom'

import './link.scss'

export type LinkComponentProps = {
  to: string
  disabled?: boolean
}

export const LinkComponent: React.FC<LinkComponentProps> = ({
  children,
  ...rest
}) => (
  <Link {...rest} className='link'>
    {children}
  </Link>
)
