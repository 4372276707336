type ErrorData = { status: number; message: string }

export class ErrorException extends Error {
  status: number | null = null
  message = ''

  constructor({ message, status }: ErrorData) {
    super()
    this.name = 'ErrorException'
    this.status = status
    this.message = message
  }
}
