import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'src/store'

export const getMaintenanceSelector = (
  state: RootState,
): RootState['maintenance'] => state.maintenance

export const getMaintenance = createSelector(
  getMaintenanceSelector,
  state => state.enabled,
)
