import React, { useState } from 'react'

import { Navbar } from './Navbar'
import { NavModules } from './Navbar.types'

type UseNavbarProps = {
  navModules: NavModules
}

export const useNavbar = ({ navModules }: UseNavbarProps) => {
  const [opened, setOpened] = useState(false)

  const onToggleHandler = () => {
    setOpened(prev => !prev)
  }

  return {
    opened,
    Navbar: (
      <Navbar
        opened={opened}
        onToggle={onToggleHandler}
        navModules={navModules}
        onClose={() => setOpened(false)}
      />
    ),
  }
}
