import React, { useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useAppDispatch } from 'src/store'
import { login } from 'src/store/feature/user'
import { LinkComponent } from 'src/components/link'

import '../../auth.scss'
import { ErrorException } from 'src/services/apiService/exceptions/error.exception'

const AuthorizationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Поле должно быть в формате email!')
    .required('Обязательное поле'),
  password: yup.string().trim().required('Обязательное поле'),
})

export const Login: React.FC = () => {
  const [loginError, setLoginError] = useState('')
  const dispatch = useAppDispatch()
  const history = useHistory()
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false,
      showPassword: false,
    },
    validationSchema: AuthorizationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const message = {
        username: values.email,
        password: values.password,
      }
      setLoginError('')
      dispatch(login(message))
        .then(res => {
          if (res.meta.requestStatus === 'rejected') {
            if (res.payload instanceof ErrorException) {
              setLoginError(res.payload.message)
              return
            }
          }
          history.push('/')
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })
  const emailInputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    emailInputRef?.current?.focus()
  }, [])

  return (
    <div className='security'>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          name='email'
          type='text'
          label='Логин'
          variant='outlined'
          fullWidth={true}
          onChange={formik.handleChange}
          error={
            (formik.touched.email && Boolean(formik.errors.email)) ||
            Boolean(loginError)
          }
          helperText={
            (formik.touched.email && formik.errors.email) || loginError
          }
          inputProps={{
            ref: emailInputRef,
          }}
        />
        <TextField
          name='password'
          type={formik.values.showPassword ? 'text' : 'password'}
          label='Пароль'
          variant='outlined'
          fullWidth={true}
          onChange={formik.handleChange}
          error={
            (formik.touched.password && Boolean(formik.errors.password)) ||
            Boolean(loginError)
          }
          helperText={
            (formik.touched.password && formik.errors.password) || loginError
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() =>
                    formik.setFieldValue(
                      'showPassword',
                      !formik.values.showPassword,
                    )
                  }
                >
                  {formik.values.showPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className='security__actions'>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            fullWidth={true}
            size='large'
            disabled={formik.isSubmitting}
          >
            Войти
          </Button>
        </div>
        <div className='security__links'>
          <LinkComponent to='sign-up'>Регистрация</LinkComponent>
          <LinkComponent to='restore'>Забыли пароль?</LinkComponent>
        </div>
      </form>
    </div>
  )
}
