import { createAsyncThunk } from '@reduxjs/toolkit'

import { WithData } from 'src/@types/api'
import { apiClient } from 'src/services/apiClient'

import { AgreementPageTabs } from '../../../constants/routes'

import { TGetAgreementsParams, TAgreements, TCatalog } from './types'

export const getAgreements = createAsyncThunk<
  WithData<TAgreements>,
  TGetAgreementsParams
>(
  'agreements/getAgreements',
  async ({ type, ...data }, { rejectWithValue }) => {
    const [err, res] = await apiClient.post<any, WithData<TAgreements>>({
      path: `/agreements/${type}/page`,
      body: data,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    return res.data
  },
)

export const getAgreementCatalog = createAsyncThunk<
  WithData<TCatalog>,
  AgreementPageTabs
>('agreements/getAgreementCatalog', async (type, { rejectWithValue }) => {
  const [err, res] = await apiClient.get<WithData<TCatalog>>({
    path: `/agreements/${type}/catalog`,
  })

  if (err) {
    throw rejectWithValue(err)
  }
  return res.data
})
