/* eslint-disable prefer-named-capture-group */
export const ValidationHelper = {
  testKpp: (value?: string): boolean => {
    if (!value) {
      return false
    }

    return Boolean(/^\d{9}$/u.test(value))
  },

  testInn: (value?: string): boolean => {
    if (!value) {
      return false
    }

    let isValid = false
    const multipliers = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]
    const ch: Array<number> = [0, 0, 0]

    const innArray: Array<number> = value.split('').map(item => +item)

    for (let i = 0; i < 12; i++) {
      for (let j = 0; j < 3; j++) {
        if (multipliers[i + j]) {
          ch[j] += innArray[i] * multipliers[i + j]
        }
      }
    }

    if (innArray.length === 10) {
      isValid = innArray[9] === (ch[2] % 11) % 10
    }
    if (innArray.length === 12) {
      isValid =
        innArray[10] === (ch[1] % 11) % 10 && innArray[11] === (ch[0] % 11) % 10
    }

    return isValid
  },

  testPassword: (value?: string): boolean => {
    if (!value) {
      return false
    }

    // eslint-disable-next-line require-unicode-regexp
    return !!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/)
  },

  testEmail: (value?: string): boolean => {
    if (!value) {
      return false
    }
    return Boolean(
      value.match(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ),
    )
  },
}
