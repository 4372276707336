import { createSlice } from '@reduxjs/toolkit'
import { ProviderDetail, ProviderInfo } from 'src/@types/api'

import { getProviderDetailAction, getProviderInfo } from './actions'

type initialStateProps = {
  isLoading: boolean
  isOpenEditProviderModal: boolean
  detail: ProviderDetail
  info: ProviderInfo
}

const initialState: initialStateProps = {
  isLoading: false,
  isOpenEditProviderModal: false,
  detail: {} as ProviderDetail,
  info: {} as ProviderInfo,
}

const provider = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    providerDetailReset: () => initialState,
    closeEditProviderModal: state => {
      state.isOpenEditProviderModal = false
    },
  },
  extraReducers: builder => {
    builder.addCase(getProviderDetailAction.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getProviderDetailAction.fulfilled, (state, { payload }) => {
      state.detail = payload.data
      state.isLoading = false
    })
    builder.addCase(getProviderDetailAction.rejected, state => {
      state.isLoading = false
    })

    builder.addCase(getProviderInfo.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getProviderInfo.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.info = payload.data
      state.isOpenEditProviderModal = true
    })
    builder.addCase(getProviderInfo.rejected, state => {
      state.isLoading = false
    })
  },
})

export const { providerDetailReset, closeEditProviderModal } = provider.actions

export default provider.reducer
