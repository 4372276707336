import { ArrowDown } from '@gmini/ui-kit'

import { ClickAwayListener } from '@material-ui/core'
import React from 'react'

import {
  ModulesNavButton,
  ModulesNavPopover,
  ModulesNavPopoverItem,
  ModulesNavPopoverDivider,
  NavbarWrapper,
} from './Navbar.styled'
import { NavModules } from './Navbar.types'

type NavbarProps = {
  opened: boolean
  onToggle: () => void
  navModules: NavModules
  onClose: () => void
}

export const Navbar = ({
  onToggle,
  opened,
  navModules,
  onClose,
}: NavbarProps) => {
  const [ourModules, anotherModules, gStationModules] = navModules
  return (
    <NavbarWrapper>
      <ModulesNavButton
        hover={opened}
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()
          onToggle()
        }}
      >
        <ArrowDown />
      </ModulesNavButton>
      {opened && (
        <ClickAwayListener onClickAway={onClose}>
          <ModulesNavPopover onClick={e => e.stopPropagation()}>
            {gStationModules.map(module => (
              <a
                target='_blank'
                href={module.url}
                key={module.id}
                rel='noreferrer'
              >
                <ModulesNavPopoverItem>{module.name}</ModulesNavPopoverItem>
              </a>
            ))}
            {gStationModules.length ? <ModulesNavPopoverDivider /> : null}
            {anotherModules.map(module => (
              <a
                target='_blank'
                href={module.url}
                key={module.id}
                rel='noreferrer'
              >
                <ModulesNavPopoverItem>{module.name}</ModulesNavPopoverItem>
              </a>
            ))}
            {ourModules.length ? <ModulesNavPopoverDivider /> : null}
            {ourModules.map(module => (
              <a
                target='_blank'
                href={module.url}
                key={module.id}
                rel='noreferrer'
              >
                <ModulesNavPopoverItem>{module.name}</ModulesNavPopoverItem>
              </a>
            ))}
          </ModulesNavPopover>
        </ClickAwayListener>
      )}
    </NavbarWrapper>
  )
}
