/* eslint-disable react/display-name */
import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import { format } from 'date-fns'
import isNil from 'lodash/isNil'

import { GridCellParams, GridColDef } from '@material-ui/x-grid'

import { ModuleTypes } from 'src/@types/api'
import { ReactComponent as FileIcon } from 'src/assets/svg/file.svg'
import { TendersTaskType, APP_PAGES } from 'src/constants/routes'

import { TableHeader } from '../../components/tableHeader'

import { MODULE_TYPES } from './constants'

const dispatcherType = {
  new: (tab: TendersTaskType) => [
    {
      field: 'tenderTaskNumber',
      headerName: '№ ',
      width: 170,
      required: true,
      flex: 1,
      renderHeader: () => <TableHeader headerName='№ ' />,
    },
    {
      field: 'subject',
      headerName: 'Название ТЗ',
      width: 200,
      required: true,
      flex: 1.5,
      renderCell: ({
        row: { tenderTaskNumber, lotId, id, subject, module },
      }: GridCellParams) => {
        if (tenderTaskNumber && lotId && id) {
          return (
            <Link
              to={generatePath(APP_PAGES.TENDER_TASK_DETAIL, {
                tab,
                module,
                tenderId: tenderTaskNumber,
                lotId,
                omgId: id,
              })}
            >
              {subject}
            </Link>
          )
        }
        return <div>{subject}</div>
      },
      renderHeader: () => <TableHeader headerName='Название ТЗ' />,
    },
    {
      field: 'lotName',
      headerName: 'Название лота',
      width: 200,
      flex: 1.5,
      renderHeader: () => <TableHeader headerName='Название лота' />,
    },
    {
      field: 'module',
      headerName: 'Модуль',
      width: 170,
      required: true,
      flex: 1,
      renderCell: ({ row }: GridCellParams) => {
        if (isNil(row.module)) {
          return ''
        }

        const module = MODULE_TYPES[row.module as ModuleTypes]
        return <div title={module}>{module}</div>
      },
      renderHeader: () => <TableHeader headerName='Модуль' />,
    },
    {
      field: 'specializations',
      headerName: 'Специализация',
      width: 200,
      flex: 1.5,
      renderHeader: () => <TableHeader headerName='Специализация' />,
    },
    {
      field: 'sendDateTime',
      headerName: 'Дата',
      width: 170,
      flex: 1.2,
      renderCell: ({ row }: GridCellParams) => {
        const formattedDate = format(
          new Date(row.sendDateTime),
          'dd.MM.YYY kk:mm',
        )
        return <div title={formattedDate}>{formattedDate}</div>
      },
      renderHeader: () => <TableHeader headerName='Дата' />,
    },
    {
      field: 'countOfAttachments',
      headerName: ' ',
      width: 170,
      sortable: false,
      flex: 1,
      renderCell: ({ row }: GridCellParams) => (
        <div title='Файлы' className='icon-column'>
          <FileIcon /> {row.countOfAttachments}
        </div>
      ),
      renderHeader: () => <TableHeader headerName=' ' />,
    },
  ],
}

export const createXGridColumns = (tab: TendersTaskType): GridColDef[] => {
  try {
    return dispatcherType[TendersTaskType.new](tab)
  } catch {
    throw Error('unknown type')
  }
}
